import { ChangeEvent, FocusEvent } from 'react';
import { FormikContextType } from 'formik';
import { useTranslation } from 'react-i18next';

export const handleBlurAndTrim = (
  event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>,
  // eslint-disable-next-line
  formik: FormikContextType<any>,
) => {
  const eventCopy = { ...event };
  eventCopy.target.value = eventCopy.target.value.trim();
  formik.handleBlur(eventCopy);
  formik.handleChange(eventCopy);
};

export const capitalize = (text: string) => {
  const words = text.toLowerCase().split(' ');

  if (words) {
    const capitalized = words
      .map((word) => {
        if (word !== '') {
          return word[0].toUpperCase() + word.substring(1);
        }
        return '';
      })
      .join(' ');
    return capitalized;
  }
  return '';
};

export const capitalizeOnChange = (
  event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  // eslint-disable-next-line
  formik: FormikContextType<any>,
) => {
  const eventCopy = { ...event };
  eventCopy.target.value = capitalize(event.target.value);
  formik.handleChange(eventCopy);
};

export const removeDotsAts = (text: string) => {
  // Strip from @ & . for testing purposes
  return text.replace(/[@.]/g, '');
};

export const blockNonNumericalChars = (
  event: React.KeyboardEvent<HTMLDivElement>,
) => ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();

import { DetailedEvent, Event } from '../../../types/eventTypes';
import { Box, Collapse, TableCell, TableRow } from '@material-ui/core';
import TableBodyRow from '../../../components/Table/TableBodyRow';
import DataCell from '../../../components/Table/DataCell';
import React from 'react';
import { getDetailedEvent } from '../../../api/detailedEventApi';
import { ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import { apiAddress } from '../../../api/config/interceptors';
import { useTranslation } from 'react-i18next';

type EventsTableRowProps = {
  event: Event;
}

const EventsTableRow = ({ event }: EventsTableRowProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [details, setDetails] = React.useState<DetailedEvent>();

  const getDetails = () => {
    if (!open) {
      getDetailedEvent(event.id)
        .then(response => {
          setDetails(response as DetailedEvent);
        });
    }
    setOpen(!open);
  };

  function parseImageUrl(img: string | undefined): string {
    return `${apiAddress}/images/${(img ?? '')
      .replace('Available(resourcePath=/workspace/output/reports/', '')
      .replace(')', '')}`;
  }

  return (
    <>
      <TableBodyRow key={event.id} id={`${event.id}-event-data-row`} onClick={() => getDetails()}>
        <DataCell id={`${event.id}-id-cell`}>{event.streamName}</DataCell>
        <DataCell
          id={`${event.id}-telemetry-time-cell`}> {new Date(event.telemetryTimestamp).toLocaleString()}</DataCell>
        <DataCell id={`${event.id}-status-cell`}> {t(`analytics.${event.analyticsType}`)}</DataCell>
        <DataCell id={`${event.id}-event-type-cell`}> {t(`alarms.types.${event.eventType}`)}</DataCell>
        <DataCell id={`${event.id}-event-duration`}> {`${event.eventDuration} s`}</DataCell>
      </TableBodyRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <ImageList cols={4} rowHeight={250}>
                {details != undefined && details.alarmingEvents
                  .filter(item => item.thumbnail != undefined && item.thumbnail.startsWith('Available'))
                  .map((item) => (
                    <>
                      <ImageListItem key={parseImageUrl(item.thumbnail)}>
                        <img
                          srcSet={`${parseImageUrl(item.thumbnail)}`}
                          src={`${parseImageUrl(item.thumbnail)}`}
                          alt={new Date(item.timestamp).toLocaleString()}
                          loading="lazy"
                        />
                        <ImageListItemBar
                          title={t(`alarms.types.${item.eventType}`)}
                          subtitle={new Date(item.timestamp).toLocaleString()}
                        />
                      </ImageListItem>
                    </>
                  ))}
              </ImageList>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

    </>
  );
};


export default EventsTableRow;